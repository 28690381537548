@import url("https://fonts.googleapis.com/css2?family=Recursive:wght,MONO@300..800,1&display=swap");

#single-table-designer {
  width: 100%;
  display: flex;
}

.table-designer-query-builder {
  background-color: #f5f5f5;
}

.single-table-sidepanel {
  overflow-y: "auto";
  width: 240px;
  min-width: 240px;
}

.access-pattern-item {
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}
.access-pattern-item:hover {
  background-color: #f5f5f5;
}

.highlight {
  background-color: #d6f0ff;
}

#table {
  width: 100%;
  overflow-x: scroll;
  height: 100%;
}

#table.monospace div,
#table.monospace input,
#table.monospace select {
  font-family: "Recursive", sans-serif;
}

body {
  margin: 0;
  font-family: system-ui;
  overflow-x: hidden;
}

#models-table.monospace div,
#models-table.monospace input,
#models-table.monospace select {
  font-family: "Recursive", sans-serif;
}

table {
  border-spacing: 0;
  border: 1px solid #ccc;
  border-top: none;
  font-size: 12px;
}

table input {
  background: transparent;
  font-size: 12px !important;
}

.rc-tooltip-inner {
  min-height: 1px !important;
}

tr:last-child > td {
  border-bottom: 0;
}

th:last-child,
td:last-child {
  border-right: 0;
}

.toolbar {
  user-select: none;
  background-color: #f5f5f5;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

th,
td {
  margin: 0;
  padding: 0.25rem;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
  font-size: 12px;
}

th {
  background-color: #f5f5f5;
  user-select: none;
}

tr:hover {
  background-color: #f5f5f5;
}
.shadow {
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}
.toolbar-icon {
  transition: 0.3s all;
  border-radius: 2px;
}
.toolbar-icon:hover {
  background-color: #e5e5e5;
}

.referred {
  background-color: #efe9ff !important;
  border-radius: 3px !important;
}

.ddb-type-picker {
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
  max-width: 16px;
}

.new-gsi-modal {
  padding: 20px !important;
}

.new-model-modal {
  padding: 20px !important;
}

.boolean-picker {
  border: 0;
  padding-left: 0;
  /* margin-left: -5px; */
  background: #f5f5f5;
}

.ddb-type-picker:disabled {
  opacity: 1;
  color: black;
  cursor: not-allowed !important;
}

.editable-cell-input[type="checkbox"] {
  margin-top: 3px;
}

.single-table-designer-no-items-found {
  padding: 20px;
  width: "100%";
  align-items: center;
  justify-content: center;
}

.pointer {
  cursor: "pointer";
}

.sk-remove-btn {
  margin-bottom: -1px;
  margin-top: 1px;
}

thead > tr:first-child > th:hover {
  background-color: #ccc;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

input {
  font-size: 1rem;
  padding: 0;
  margin: 0;
  border: 0;
}

tr {
  margin: 0;
}

/* ----------- context menu ----------- */

.react-contextmenu {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  color: #373a3c;
  font-size: 12px;
  margin: 2px 0 0;
  min-width: 160px;
  outline: none;
  opacity: 0;
  padding: 5px 0;
  pointer-events: none;
  text-align: left;
  transition: opacity 250ms ease !important;
}

.react-contextmenu.react-contextmenu--visible {
  opacity: 1;
  pointer-events: auto;
  z-index: 9999;
}

.react-contextmenu-item {
  background: 0 0;
  border: 0;
  color: #373a3c;
  cursor: pointer;
  font-weight: 400;
  line-height: 1.5;
  padding: 2px 14px;
  text-align: inherit;
  white-space: nowrap;
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
  color: #fff;
  background-color: #20a0ff;
  border-color: #20a0ff;
  text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
  color: #878a8c;
}

.react-contextmenu-item--divider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  cursor: inherit;
  margin-bottom: 3px;
  padding: 2px 0;
}
.react-contextmenu-item--divider:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
}

.react-contextmenu-item.react-contextmenu-submenu {
  padding: 0;
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item {
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item:after {
  content: "▶";
  display: inline-block;
  position: absolute;
  right: 7px;
}

.example-multiple-targets::after {
  content: attr(data-count);
  display: block;
}

/* ----------- modal ----------- */
.ReactModal__Content--after-open {
  padding: 0 !important;
  top: 160px !important;
  bottom: 160px !important;
  left: 160px !important;
  right: 160px !important;
}

.ReactModal__Overlay--after-open {
  background-color: rgba(0, 0, 0, 0.2) !important;
  z-index: 10000001;
  align-items: center;
  justify-content: center;
  display: flex;
}

/* ----------- rjsf ----------- */

.rjsf {
  padding: 10px;
}

fieldset {
  border: 0;
  padding: 0;
}

.rjsf input.form-control {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: block;
  width: 100%;
  padding: 8px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: inherit;
  line-height: inherit;
  border: 1px solid;
  border-radius: default;
  color: inherit;
  background-color: transparent;
  height: 36px;
  min-width: 100px;
  background-color: #fff;
  border: solid 1px #d5d5d5;
  outline: 0;
}

.rjsf .btn.btn-info[type="submit"] {
  display: none;
}

.rjsf input[readonly] {
  display: none;
}

.rjsf .field-object .form-group .row.array-item-list {
  display: block;
}
.rjsf .field-object .form-group .row {
  display: flex;
}
.rjsf .form-group .row .array-item-remove {
  margin-top: 24px;
  width: 80px;
}
.rjsf .form-group .row .array-item-remove::after {
  content: "Remove";
}

.rjsf .error-detail {
  list-style: none;
  padding-inline-start: 2px;
  margin-block-start: 2px;
  margin-block-end: 2px;
  font-size: 14px;
  color: rgb(218, 0, 0);
}

.rjsf .field {
  margin-top: 10px;
}

.field-description {
  font-size: 12px;
  color: #999;
  margin: 0px 0;
}

/* ----------- autocomplete ----------- */
.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__suggestions-list {
  list-style-type: none;
  padding: 0px !important;
  background-color: white;
  border: solid 1px #d5d5d5;
}

.react-autosuggest__suggestions-container {
  position: absolute;
  top: 21px;
  z-index: 10000;
  width: 100%;
  padding: 0 !important;
}
